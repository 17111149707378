<script lang="ts">
    import { ptBR } from "date-fns/locale";
    import { DateInput, localeFromDateFnsLocale } from "date-picker-svelte";
    import { localUserStore } from "../../Connection/LocalUserStore";
    import { WORKIN_ADM_URL } from "../../Enum/EnvironmentVariable";
    import Notification from "../Notification/Notification.svelte";

    let name = "";
    let email = "";
    let isLoading = false;
    let date = new Date();
    let locale = localeFromDateFnsLocale(ptBR);
    let notification = { message: "", type: "" };

    async function callExternalApi() {
        isLoading = true;
        const url = `${WORKIN_ADM_URL}/api/users`; // Replace with your actual API URL

        const tokenDecoded = localUserStore.getAccessToken();
        const accessToken = tokenDecoded?.accessToken;

        try {
            const response = await fetch(url, {
                method: "POST", // or 'GET' if required by your API
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    displayName: name,
                    email: email,
                    data: date,
                    message: "",
                }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            notification = { message: "Usuário convidado com sucesso!", type: "success" };
            return await response.json(); // Assuming the API returns a JSON response
        } catch (error) {
            console.error("Error sending token:", error);
            notification = { message: "Ocorreu um erro ao enviar o convite.", type: "error" };
        } finally {
            isLoading = false;
            // Auto-dismiss notification after 3 seconds
            setTimeout(() => {
                notification = { message: "", type: "" };
            }, 3000);
        }
    }
</script>

<div style={isLoading ? "height: 90%" : ""}>
    {#if isLoading}
        <div class="loading-group-menu">
            <div class="flex justify-center items-center h-full">
                <div class="tw-rounded-lg tw-text-xs tw-p-3">
                    <!-- loading animation -->
                    <div class="loading-group">
                        <span class="loading-dot" />
                        <span class="loading-dot" />
                        <span class="loading-dot" />
                    </div>
                </div>
            </div>
        </div>
    {:else}
        <section class="share-url not-mobile">
            <h3 class="blue-title">Nome do seu convidado</h3>
            <input type="text" bind:value={name} id="input-share-link" class="tw-w-full" />
        </section>
        <section class="share-url not-mobile">
            <h3 class="blue-title">E-mail do seu convidado</h3>
            <input type="text" bind:value={email} id="input-share-link" class="tw-w-full" />
        </section>
        <section class="share-url not-mobile">
            <h3 class="blue-title">Data da Reunião</h3>
            <DateInput
                class="sampleanyway"
                {locale}
                format="dd/MM/yyyy às HH:mm"
                timePrecision="minute"
                id="input-share-link"
                bind:value={date}
            />
        </section>
        <div class="centered-column">
            <button type="button" class="light" on:click={callExternalApi}>Convidar</button>
        </div>
    {/if}
</div>

<Notification message={notification.message} type={notification.type} />

<style lang="scss">
    .loading-group-menu {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :global(.sampleanyway input) {
        background: none !important;
        width: 100% !important;
        color: white !important;
    }

    @import "../../style/breakpoints.scss";
</style>
