<!-- Notification.svelte -->
<script>
    import { fade } from "svelte/transition";

    export let message = "";
    export let type = "";
</script>

{#if message}
    <div class="tw-fixed tw-top-4 tw-right-4 tw-z-50" transition:fade>
        <div
            class="tw-flex tw-items-center tw-p-4 tw-rounded-md tw-shadow-lg
      {type === 'success' ? 'tw-bg-green-500 tw-text-white' : ''}
      {type === 'error' ? 'tw-bg-red-500 tw-text-white' : ''}"
        >
            <span>{message}</span>
        </div>
    </div>
{/if}
